<template>
    <div>
        <img src="@/assets/img/home/Boda/19.jpg" class="w-100 h-100 img-background" alt="Novios abrazados">
        
      <HomeCard v-for="(card, index) in cards" :datos="card" :key="index" :id="'home-card-'+index" class="mvh-lg-100" />
    </div>
  </template>
  <script>
    import HomeCard from '@/components/home/Card.vue'
    import Img1 from '@/assets/img/home/Boda/20.jpg'
    import Img1_2 from '@/assets/img/home/2-1.png'
    import Img1_3 from '@/assets/img/home/2-2.png'
  
    import Img2 from '@/assets/img/home/Boda/21.jpg'
    import Img2_2 from '@/assets/img/home/3-1.png'
  
    import Img3 from '@/assets/img/home/Boda/22.jpg'
    import Img3_2 from '@/assets/img/home/4-1.png'
    
    export default {
      name: 'Home',
      components: {
        HomeCard
      },
      data(){
        return {
          cards: [{
            title: 'Celebra con Nosotros tu Boda',
            descripcion: "Una boda es uno de los eventos más importantes y significativos en la vida de una pareja. Más allá de ser una celebración de amor, es un rito de paso que marca el comienzo de una nueva etapa en la vida de dos personas que deciden unir sus vidas en matrimonio. Este evento está lleno de simbolismo y emociones profundas, y su significado puede ser multifacético. En primer lugar, una boda es la celebración del amor y el compromiso mutuo. Es una declaración pública de la decisión de dos personas de compartir sus vidas, apoyarse mutuamente y construir un futuro juntos. Este compromiso se expresa a través de los votos matrimoniales, en los cuales los novios prometen amarse y respetarse en todas las circunstancias que la vida les presente. La boda también simboliza la unión de dos familias. No solo se unen dos  personas, sino que también se crea un vínculo entre sus respectivas familias. Es una oportunidad para fortalecer lazos familiares, crear nuevas relaciones y celebrar la ampliación de la red de apoyo y amor que rodea a los novios. Además, una boda es un acto de celebración comunitaria. Amigos y seres queridos se reúnen para acompañar a la pareja en este momento trascendental, compartiendo su alegría y felicidad. La presencia de estos testigos no solo enriquece la experiencia de la boda, sino que también sirve como un recordatorio de la importancia de la comunidad y el apoyo social en la vida matrimonial. El simbolismo de una boda también se refleja en sus tradiciones y rituales. Desde el intercambio de anillos, que representan un amor sin fin, hasta el primer baile, que simboliza la primera de muchas experiencias compartidas, cada elemento de la ceremonia tiene un significado especial. Estos rituales, que pueden variar cultural y religiosamente, sirven para reafirmar los valores y creencias de la pareja, así como para honrar las tradiciones familiares.",
            img1: Img1,
            img2: Img1_2,
            img3: Img1_3,
            bg: 'bg-primary text-white',
            align: 'end',
            url: 'como-funciona.index',
            urlname: 'Como funciona',
            urlClass: 'btn-light',
            hide: true
          },
          {
            title: 'Aprovecha cada Momento',
            descripcion: ' Una boda también marca el inicio de una nueva etapa de vida. Es el punto de partida para la construcción de una vida en común, donde la pareja trabajará junta para alcanzar metas, superar desafíos y construir un hogar lleno de amor y respeto. Este nuevo capítulo viene acompañado de esperanzas y sueños compartidos, así como del compromiso de crecer juntos y apoyarse mutuamente a lo largo de los años. Finalmente, una boda es una expresión de esperanza y optimismo hacia el futuro. Al casarse, la pareja no solo celebra su amor actual, sino que también mira hacia adelante con la confianza de que podrán enfrentar cualquier desafío que la vida les presente, sabiendo que lo harán juntos. Es un acto de fe en el futuro y en la capacidad de su amor para perdurar y fortalecerse con el tiempo. En resumen, una boda es una celebración multifacética que abarca amor, compromiso, familia, comunidad, tradición y esperanza. Es un momento para honrar el pasado, celebrar el presente y mirar con ilusión hacia el futuro, todo mientras se rodea de los seres queridos que acompañan y apoyan a la pareja en su nuevo viaje juntos.',
            img1: Img2,
            img2: Img2_2,
            bg: 'bg-secondary text-white',
            align: 'start',
            hide: true,
          },
          {
            title: 'Palabras de Agradecimiento',
            descripcion: 'Nos encontramos todavía sumidos en la alegría y la emoción de nuestro día de boda, y nos sentimos profundamente conmovidos por el amor y el apoyo que hemos recibido de cada uno de ustedes. Primero que nada, queremos expresar nuestro más sincero agradecimiento a nuestras familias. Ustedes han sido nuestros pilares, nuestros guías y nuestros mayores ejemplos de amor y dedicación. Desde el primer día, nos han apoyado incondicionalmente en este viaje, y por eso les estamos eternamente agradecidos. A nuestros padres, queremos decirles que su amor y sacrificio no han pasado desapercibidos. Han sido un ejemplo de lo que significa comprometerse, sacrificarse y trabajar arduamente por el bienestar de la familia. Nos sentimos bendecidos de tenerlos como modelos a seguir en nuestra propia vida matrimonial. A nuestros amigos y seres queridos, queremos agradecerles por estar con nosotros en este día tan especial. Sus palabras de aliento, sus abrazos cálidos y su presencia significaron el mundo para nosotros. La alegría de compartir este momento con ustedes ha hecho que nuestro día de boda sea aún más especial y memorable. A todos aquellos que contribuyeron de alguna manera a nuestra boda, ya sea con su tiempo, su talento o su generosidad, queremos expresar nuestro profundo agradecimiento. Desde aquellos que nos ayudaron a planificar cada detalle, hasta aquellos que nos brindaron su apoyo emocional en los momentos más estresantes, estamos verdaderamente agradecidos por su contribución a nuestro día especial. Por último, pero no menos importante, queremos agradecernos mutuamente. Hoy hemos dado un paso significativo en nuestro viaje juntos, y no podríamos estar más emocionados por lo que el futuro nos depara. Nos comprometemos a seguir amándonos, apoyándonos y construyendo juntos una vida llena de amor, aventuras y felicidad.',
            img1: Img3,
            img2: Img3_2,
            bg: 'bg-light text-dark',
            align: 'end',
            hide: true
          }]
        }
      },
      mounted(){
        window.addEventListener('scroll', this.handleScroll);
      },
      unmounted(){
        window.removeEventListener('scroll', this.handleScroll)
      },
      methods: {
        handleScroll () {
          for (let index = 0; index < this.cards.length; index++) {
            var top = document.getElementById('home-card-'+index).offsetTop
            if(document.documentElement.scrollTop >= (top-20)){
              if(this.cards[index]['hide']){
                this.cards[index]['hide'] = false
              }
            }
          }
        }
      }
    }
  </script>
    